import { I18nModule } from '@agdir/i18n/angular';
import { Component, input } from '@angular/core';
import { NzFormModule } from 'ng-zorro-antd/form';

@Component({
	standalone: true,
	selector: 'agdir-form-item',
	template: `
		<nz-form-item>
			<nz-form-label>{{ label() | transloco }}</nz-form-label>
			<nz-form-control>
				<ng-content></ng-content>
			</nz-form-control>
		</nz-form-item>
	`,
	imports: [NzFormModule, I18nModule],
})
export class AgdirFormItemComponent {
	label = input.required<string>();
}
