import { Share } from '@agdir/domain';
import { SharesService } from '@agdir/services';
import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { computedAsync } from 'ngxtension/computed-async';
import { firstValueFrom } from 'rxjs';
import { AgdirSelectComponent } from '../agdir-select.component';
import { AgdirSelectOption, SelectSizeType } from '../model-components/agdir-select-model.component';

@Component({
	standalone: true,
	selector: 'agdir-person-select',
	template: `
		<agdir-select
			class="w-full"
			[options]="options()"
			[label]="label()"
			[value]="value()"
			[translate]="translate()"
			[size]="size()"
			[description]="description()"
			[horizontal]="horizontal()"
			[selectSize]="selectSize()"
			[placeholder]="placeholder()"
			(changed)="emitSelected($event)"
		/>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [AgdirSelectComponent],
})
export class PersonSelectComponent {
	share$ = inject(SharesService).getCurrentCompanyCustomers();
	selectedCustomerId = input<string>('');
	options = computedAsync<AgdirSelectOption<Share>[]>(
		async () => {
			const shares = await firstValueFrom(this.share$);
			return shares.map((share) => ({
				value: share,
				label: `${share.customer?.name} ${share.customer?.surname} (${share.customer?.phoneNumber})`,
			}));
		},
		{ initialValue: [] },
	);
	value = computed(() => {
		return this.options()?.find((option) => option.value.customerId === this.selectedCustomerId());
	});
	label = input<string>();
	translate = input<'false' | 'true' | boolean>(true);
	size = input<'normal' | 'large' | 'medium' | 'default'>('default');
	selectSize = input<SelectSizeType>('default');
	description = input<string>('');
	horizontal = input<'false' | 'true' | boolean>();
	placeholder = input<string>();
	selectionChanged = output<Share>();

	emitSelected($event: AgdirSelectOption<Share>) {
		this.selectionChanged.emit($event.value);
	}
}
