import { Component, computed, inject, input, model, signal } from '@angular/core';
import { ButtonComponent } from '@agdir/ui/button';
import { ItemNamePipe } from '@agdir/core/angular';
import { TranslocoPipe } from '@ngneat/transloco';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { I18nService } from '@agdir/i18n/angular';
import { Equipment } from '@agdir/vogn/domain';
import { JsonPipe } from '@angular/common';
import { AgdirDatatableComponent, AgdirDatatableEntity } from '@agdir/datatable';
import { VognService } from '../../../../../../apps/shell-farm/src/app/pages/vogn/services/vogn.service';
import { shareReplay } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
	standalone: true,
	selector: 'agdir-add-equipment',
	template: `
		<agdir-button (click)="open()" size="large" icon="add_circle" label="{{ label() }}" color="primary" />
		<nz-modal [nzVisible]="isVisible()" [nzTitle]="title() | transloco">
			<ng-container *nzModalContent>
				<agdir-datatable [data]="datatableItems()" (toggleSelection)="toggleSelection($event)" [nameLabel]="nameLabel() | transloco" />
			</ng-container>
			<ng-container *nzModalFooter>
				<agdir-button color="ghost" label="general.closeText" (click)="cancel()" />
				<agdir-button color="green" size="large" (click)="submit()" label="general.saveText" />
			</ng-container>
		</nz-modal>
	`,
	imports: [ButtonComponent, ItemNamePipe, TranslocoPipe, NzModalModule, JsonPipe, AgdirDatatableComponent],
})
export class AddEquipmentComponent {
	nzModalService = inject(NzModalService);
	title = input<string>('');
	label = input<string>('');
	nameLabel = input<string>('');
	description = input<string>('');
	currentSelection = model<Equipment[]>([]);
	i18n = inject(I18nService);

	isVisible = signal(false);

	selectedEquipment: Equipment[] = [];

	selectedItems = new Set<number>([]);

	equipment$ = inject(VognService).getEquipment().pipe(shareReplay(1));
	equipmentSignal = toSignal(this.equipment$);

	datatableItems = computed(() => this.equipmentSignal()?.map((item) => this.equipmentToAgdirDatatableEntity(item)) || []);

	async toggleSelection(datatableEntity: AgdirDatatableEntity): Promise<void> {
		const equipmentId = datatableEntity.id;
		if (this.selectedItems.has(equipmentId)) {
			this.selectedEquipment = this.selectedEquipment.filter((item) => +item.id !== equipmentId);
		} else {
			const originalEquipment = this.equipmentSignal()?.find((item) => +item.id === equipmentId);
			if (originalEquipment) {
				this.selectedEquipment.push(originalEquipment);
			}
		}
	}

	cancel() {
		this.isVisible.set(false);
		this.nzModalService.closeAll();
	}

	submit() {
		this.isVisible.set(false);
		this.nzModalService.closeAll();
		this.currentSelection.update(() => this.selectedEquipment);
	}

	open() {
		this.isVisible.set(true);
	}

	private equipmentToAgdirDatatableEntity(classifier: Equipment): AgdirDatatableEntity {
		return {
			name: classifier.name || '???',
			id: +classifier.id,
			selected: this.selectedItems.has(+classifier.id),
			model: classifier,
			expand: false,
		};
	}
}
