import { AgdirCardComponent, ResponsiveService } from '@agdir/core/angular';
import { Invitation, InvitationService, InvitationStatus } from '@agdir/heimdall';
import { CUSTOMER_PATHS, SharesService } from '@agdir/services';
import { AgdirConfirmationService } from '@agdir/ui';
import { BadgeComponent } from '@agdir/ui/badge';
import { ButtonComponent } from '@agdir/ui/button';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, model, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoDirective, TranslocoPipe } from '@ngneat/transloco';
import { NzSwitchComponent } from 'ng-zorro-antd/switch';
import { derivedAsync } from 'ngxtension/derived-async';

@Component({
	standalone: true,
	selector: 'agdir-company-invitations-list',
	template: `
		<agdir-card title="invitations.company.title" subtitle="invitations.company.subtitle">
			<div menu class="flex-row-end gap-1">
				<span transloco="invitations.showAllToggle"></span>
				<nz-switch [(ngModel)]="seeAll"></nz-switch>
			</div>
			<table class="agdir-table" [class.hidden]="!invitations().length">
				<thead>
					<tr>
						<th></th>
						<th transloco="invitations.status.invitedBy"></th>
						<th transloco="invitations.status.invitedAt"></th>
						<th class="text-right text-nowrap" transloco="invitations.status.status"></th>
					</tr>
				</thead>
				<tbody>
					@for (i of invitations(); track $index) {
						<tr>
							<td>
								{{ i.invitee?.name }}
								{{ i.invitee?.surname }}
								{{ i.invitee?.phoneNumber }}
							</td>
							<td>
								<span>{{ i.inviter?.name }}</span>
							</td>
							<td>
								<time [innerText]="i.createdAt | date: 'shortDate'"></time>
							</td>
							<td class="text-right">
								<div class="flex-row-end">
									<agdir-badge
										[color]="i.status === 'pending' ? 'blue' : i.status === 'accepted' ? 'green' : 'red'"
										label="{{ 'invitations.status.' + i.status | transloco }}"
									/>
									@if (i.status !== 'accepted') {
										<agdir-button
											class="hidden md:block"
											(click)="deleteInvitation(i)"
											[disabled]="isDeleting().has(i)"
											[isProcessing]="isDeleting().has(i)"
											[attr.data-cy]="'revokeInvite' + i?.company?.id"
											color="red-ghost"
											icon="delete"
											[label]="isMobile() || isTablet() ? '' : 'invitations.status.revokeInvite'"
										/>
									}
								</div>
							</td>
						</tr>
					}
				</tbody>
			</table>
		</agdir-card>
	`,
	host: { class: 'flex flex-col gap-1 md:gap-2' },
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [AgdirCardComponent, TranslocoDirective, NzSwitchComponent, FormsModule, ButtonComponent, DatePipe, TranslocoPipe, BadgeComponent],
})
export class CompanyInvitationsComponent {
	seeAll = model(false);
	showGoToFarm = input(false);
	isDeleting = signal(new Set<Invitation>());
	localInvitations = signal<Invitation[] | null>(null);
	deleteService = inject(AgdirConfirmationService);
	responsiveService = inject(ResponsiveService);
	isMobile = this.responsiveService.isMobile();
	isTablet = this.responsiveService.isTablet(true);
	sharesService = inject(SharesService);
	protected readonly CUSTOMER_PATHS = CUSTOMER_PATHS;
	protected readonly history = history;
	protected readonly InvitationStatus = InvitationStatus;
	private readonly invitationService = inject(InvitationService);
	invitations = derivedAsync(
		async () => {
			const seeAll = this.seeAll();
			const localInvitations = this.localInvitations();
			const i = localInvitations || this.invitationService.currentCompanyInvitesSignal();
			return i?.filter((i) => seeAll || i.status === InvitationStatus.PENDING || i.status === InvitationStatus.EXPIRED) || [];
		},
		{ initialValue: [] },
	);

	async deleteInvitation(i: Invitation) {
		const confirmed = await this.deleteService.genericDelete(`${i.invitee?.name} ${i.invitee?.surname}`);
		if (!confirmed) {
			return;
		}
		i.status = InvitationStatus.DELETED;
		i.changedAt = new Date();
		this.isDeleting.update((s) => s.add(i));
		await this.invitationService.delete(i);
		this.isDeleting.update((s) => {
			s.delete(i);
			return s;
		});
		this.localInvitations.set(this.invitations().filter((inv) => inv.code !== i.code));
	}

	goToFarm(i: Invitation) {
		window.location.href = `/${i.company?.id}`;
	}
}
