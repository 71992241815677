import { AgdirIconComponent } from '@agdir/agdir-icons';
import { Classifier } from '@agdir/classifiers';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, output, Signal, signal, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoDirective, TranslocoPipe } from '@ngneat/transloco';
import { AgdirAlertComponent } from 'libs/agdir-alert/src';
import { ConcreteLocation, Field, LocationType } from 'libs/domain/src';
import { CompanyService, FieldService } from 'libs/services/src';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { computedAsync } from 'ngxtension/computed-async';
import { firstValueFrom, map, Subject } from 'rxjs';
import { ButtonComponent } from '../../../../ui/button';
import { CropTypeMultiSelectComponent } from '../components/crop-type-multi-select.component';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-field-select',
	standalone: true,
	styles: [
		`
			table {
				@apply mx-auto;
			}
		`,
		`
			th {
				@apply text-left;
			}
		`,
		`
			td,
			th {
				padding: 0.2rem 0.5rem;
				@apply text-lg;
			}
		`,
	],
	template: `
		@if (label() && size() == 'normal') {
			<nz-form-label>{{ translate() ? (label() | transloco) : label() }}</nz-form-label>
		}
		@if (label() && size() == 'large') {
			<div class="text-3xl md:text-4xl font-bold">{{ translate() ? (label() | transloco) : label() }}</div>
		}
		@if (description() && size() == 'large') {
			<div class="text-xl flex-1 font-light mb-5">{{ translate() ? (description() | transloco) : description() }}</div>
		}

		<nz-modal [nzVisible]="open()" (nzOnCancel)="open.set(false)" (nzOnOk)="open.set(false)">
			<ng-container *nzModalContent>
				@if (showCropType()) {
					<agdir-crop-type-multi-select [selected]="selectedCrops" class="flex flex-1 w-full border-b py-3 my-3" />
				}

				<div class="flex items-center justify-start mb-5 flex-wrap gap-5">
					<span [innerHtml]="linkToManagement() | transloco: { companyId: currentCompanyId$() }"></span>
					<agdir-button color="ghost" icon="redo" (click)="reloadLocations.next()" size="small" />
				</div>

				@if (cropTypeFilterStats$().unmatched > 0) {
					<agdir-alert
						type="warning"
						class="mb-3"
						showAction="true"
						actionLabel="cropTypeSelector.clearFilter"
						(actionTriggered)="this.selectedCrops.set([])"
						title="{{ 'cropTypeSelector.locationsFilteredByCrops' | transloco: cropTypeFilterStats$() }}"
					/>
				}

				<nz-table
					#table
					nzSize="small"
					[nzData]="filteredLocations$()"
					[nzFrontPagination]="true"
					[nzPageSize]="10"
					[nzPageSizeOptions]="[10, 20, 30, 50, 100]"
				>
					<thead>
						<tr>
							<th>
								<input type="checkbox" (change)="toggleAll($any($event.target).checked)" />
							</th>
							<th [transloco]="labels()?.tableHeaderField || ''"></th>
							@if (showCropType() === true || showCropType() === 'true') {
								<th [transloco]="labels()?.tableHeaderCrops || ''"></th>
							}
							@if (showProximityToWater() === true || showProximityToWater() === 'true') {
								<th [transloco]="labels()?.tableHeaderProximityToWater || ''"></th>
							}
							<th></th>
						</tr>
					</thead>
					<tbody>
						@for (item of table.data; track '_id') {
							<tr class="border-b">
								<td>
									<input
										type="checkbox"
										[checked]="isSelected(item.id)"
										(change)="toggleSelection(item.id, $any($event.target).checked)"
									/>
								</td>
								<td (click)="toggleSelection(item.id, !isSelected(item.id))">{{ item.name }}</td>
								@if (showCropType() === true || showCropType() === 'true') {
									<td (click)="toggleSelection(item.id, !isSelected(item.id))">{{ item.cropType | transloco }}</td>
								}
								@if (showProximityToWater() === true || showProximityToWater() === 'true') {
									<td (click)="toggleSelection(item.id, !isSelected(item.id))">
										@if (item.proximityToWater?.isNearWater === true) {
											{{ 'general.yesText' | transloco }}
										} @else if (item.proximityToWater?.isNearWater === false) {
											{{ 'general.noText' | transloco }}
										} @else {
											<agdir-icon icon="error" style="color: orange;" />
										}
									</td>
								}
								<td>
									<a [attr.href]="'/' + item.companyId + '/field/' + item.id" target="_blank">
										<agdir-icon icon="edit" />
									</a>
								</td>
							</tr>
						}
					</tbody>
				</nz-table>
			</ng-container>
			<ng-container *nzModalFooter>
				<agdir-button color="ghost" label="general.closeText" (click)="open.set(false)" />
				<agdir-button color="green" size="large" (click)="updateFormControlWithSelectedItems()" label="general.saveText" />
			</ng-container>
		</nz-modal>
	`,
	imports: [
		MatFormFieldModule,
		MatSelectModule,
		NzFormModule,
		NzGridModule,
		NzSelectModule,
		ReactiveFormsModule,
		TranslocoPipe,
		CropTypeMultiSelectComponent,
		ButtonComponent,
		NzIconModule,
		TranslocoDirective,
		AgdirAlertComponent,
		NzModalModule,
		NzTableModule,
		AgdirIconComponent,
	],
})
export class LocationSelectTableComponent {
	changed = output<Field[]>();
	selectedLocationIds = input<string[]>();
	locations = input<ConcreteLocation[]>([]);
	label = input<string>('');
	size = input<string>('');
	description = input<string>('');
	showCropType = input<'false' | 'true' | boolean>(false);
	showProximityToWater = input<'false' | 'true' | boolean>(false);
	labels = input<{
		tableHeaderField?: string;
		tableHeaderCrops?: string;
		tableHeaderProximityToWater?: string;
	}>({});
	translate = input<'false' | 'true' | boolean>(true);
	emptyOption = input<string>('');
	locationTypes = input<LocationType[]>([]);
	linkToManagement = input<string>('');

	selectedCrops = signal<Classifier[]>([]);
	cropTypeMultiSelect = viewChild(CropTypeMultiSelectComponent);

	locationsService = inject(FieldService);
	allLocations = computedAsync(async () => {
		const reloadedLocations = this.reloadLocation$();
		const locationTypes = this.locationTypes();
		const locations = await firstValueFrom(this.locationsService.fetchLocations<Field & { id: string }>());
		return locations.filter((location) => !locationTypes.length || (location.locationType && locationTypes.includes(location.locationType)));
	});
	reloadLocations = new Subject<void>();
	reloadLocation$ = toSignal(this.reloadLocations.pipe(map(() => Math.random())));

	filteredLocations$ = computed(() => {
		return this.filterByCrop(this.allLocations() || [], this.selectedCrops()).sort((a, b) => a.name?.localeCompare(b?.name));
	});
	cropTypeFilterStats$ = computed(() => {
		const allItems = this.allLocations() || [];
		const items = this.filteredLocations$();
		return {
			total: allItems.length,
			matched: items.length,
			unmatched: allItems.length - items.length,
		};
	});

	private companyService = inject(CompanyService);
	currentCompanyId$: Signal<string> = computed(() => this.companyService.currentCompanySignal()?.id || '');
	selectedItems = signal<Set<string>>(new Set<string>());
	open = signal<boolean>(false);
	selectedFilteredItems = computed(() => {
		const items = this.filteredLocations$();
		console.log(items);
		return items.filter((location) => this.isSelected(location.id));
	});

	constructor() {
		effect(
			() => {
				const selectedLocationIds = this.selectedLocationIds() || [];
				this.selectedItems.update((items) => {
					items.clear();
					return items;
				});
				selectedLocationIds.forEach((locationId) => this.selectedItems.update((items) => items.add(locationId) && items));
			},
			{ allowSignalWrites: true },
		);
		effect(
			() => {
				this.locations().forEach((location: ConcreteLocation) => {
					if (location.cropType) {
						this.cropTypeMultiSelect()?.addCropByCode(location.cropType, { emit: false });
					}
					this.selectedItems.update((items) => items.add(String(location.id)) && items);
				});
			},
			{ allowSignalWrites: true },
		);
	}

	private filterByCrop(locations: ConcreteLocation[], crops: Classifier[]): ConcreteLocation[] {
		return locations.filter((location) => crops?.length === 0 || crops?.some((crop) => crop.matchesCode(location.cropType, { recursive: true })));
	}

	async toggleSelection(locationId: string, isChecked: boolean, withSave = false): Promise<void> {
		if (isChecked) {
			this.selectedItems.update((items) => items.add(locationId) && items);
		} else {
			this.selectedItems.update((items) => {
				items.delete(locationId);
				return items;
			});
		}
		if (withSave) {
			await this.updateFormControlWithSelectedItems();
		}
	}

	async toggleAll(isChecked: boolean): Promise<void> {
		if (!this.filteredLocations$()?.length) {
			return;
		}
		this.selectedItems.update((items) => {
			items.clear();
			return items;
		});
		if (isChecked) {
			this.filteredLocations$().forEach((location) => this.selectedItems.update((items) => items.add(String(location.id)) && items));
		}
	}

	isSelected(locationId: string): boolean {
		return this.selectedItems().has(locationId);
	}

	async updateFormControlWithSelectedItems() {
		const selectedIds = [...this.selectedItems()];
		const allItems = this.allLocations() || [];
		const selectedItems = allItems.filter((item) => selectedIds.includes(String(item.id)));
		this.changed.emit(selectedItems);
		this.open.set(false);
	}
}
