import { LocationAddress, WeatherLocation } from '@agdir/domain';
import { BadgeComponent } from '@agdir/ui/badge';
import { ButtonComponent } from '@agdir/ui/button';
import { AgdirColors } from '@agdir/ui/colors';
import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, output, signal, viewChild } from '@angular/core';
import { MatMenu, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { add } from 'date-fns';
import { firstValueFrom } from 'rxjs';

@Component({
	standalone: true,
	selector: 'agdir-company-address-find-me-component',
	imports: [ButtonComponent, MatMenuModule, NgIf, BadgeComponent],
	template: `
		<agdir-button (click)="findMe()" color="primary" icon="my_location" [isProcessing]="busy()" label="Find an address near me" />
		<agdir-badge color="red" *ngIf="error()">{{ error() }}</agdir-badge>
	`,
	styles: [':host{@apply flex flex-col items-center justify-center;}'],
})
export class CompanyAddressFindMeComponent {
	trigger = viewChild(MatMenuTrigger);
	menu = viewChild<MatMenu>('menu');
	busy = signal(false);
	error = signal<string | null>(null);
	companyAddress = output<LocationAddress>();
	AgdirColors = AgdirColors;
	protected readonly add = add;
	private readonly http = inject(HttpClient);

	findMe() {
		if (navigator.geolocation) {
			this.busy.set(true);
			// Get the user's current position
			navigator.geolocation.getCurrentPosition(
				async (position) => {
					const location = await firstValueFrom(
						this.http.post<WeatherLocation | null>(`/freya/reverse-geocode`, {
							type: 'Point',
							coordinates: [position.coords.longitude, position.coords.latitude],
						}),
					);
					if (location) {
						this.companyAddress.emit(location.address);
					}
					this.busy.set(false);
				},
				(error) => {
					this.busy.set(false);
					console.log(error);
					this.error.set('Unable to retrieve your location. Try again later.	');
					console.log('Error occurred. Error code: ' + error.code + '. Error message: ' + error.message);
				},
			);
		} else {
			this.error.set('Geolocation is not supported by this browser.');
			console.log('Geolocation is not supported by this browser.');
		}
	}

	hydrateWithLocation(location: WeatherLocation) {}
}
