import { BusinessBranch, Company, CompanyAccessLevel, LocationAddress } from '@agdir/domain';
import { SharesService } from '@agdir/services';
import { computed, inject, signal } from '@angular/core';
import { derivedAsync } from 'ngxtension/derived-async';
import { firstValueFrom } from 'rxjs';
import { InvitationWithProgress } from '../invitations';
import { CompanyBaseInformation } from './domain';

export class CompanyBuilder {
	private readonly sharesService = inject(SharesService);

	storedCompanyState = signal<Company>({ businessBranch: BusinessBranch.FARM } as Company);

	company = derivedAsync<Company>(
		async () => {
			const myCompanies = await firstValueFrom(this.sharesService.getMyCompanies());
			const myCompany = myCompanies.find((s) => s.permission === CompanyAccessLevel.OWNER)?.company;
			return { ...myCompany, ...this.storedCompanyState() };
		},
		{ initialValue: {} as Company },
	)!;

	invitations = signal<InvitationWithProgress[]>([]);
	emailInvitations = computed(() => this.invitations().filter((i) => i.sendEmail));
	phoneInvitations = computed(() => this.invitations().filter((i) => i.sendSms));

	constructor() {
		this.readFromLocalStorage();
	}

	addCompanyBaseInformation(update: CompanyBaseInformation): CompanyBuilder {
		this.storedCompanyState.update((company) => ({
			...company,
			...update,
		}));
		this.storeInLocalStorage();
		return this;
	}

	addCompanyAddress(address: LocationAddress): CompanyBuilder {
		this.storedCompanyState.update((company) => ({
			...company,
			address: { ...(address || {}) },
		}));
		this.storeInLocalStorage();
		return this;
	}

	addInvitation(invitation: InvitationWithProgress): CompanyBuilder {
		this.invitations.set([
			...this.invitations(),
			{
				sendSms: invitation.sendSms,
				sendEmail: invitation.sendEmail,
				acceptInvitation: invitation.acceptInvitation,
				phoneNumberValidated: invitation.phoneNumberValidated,
				forceCreateInvitee: invitation.forceCreateInvitee,
				permission: invitation.permission,
				invitee: {
					name: invitation.invitee.name,
					surname: invitation.invitee.surname,
					email: invitation.invitee.email,
					phoneNumber: invitation.invitee.phoneNumber,
					id: '',
				},
			} as InvitationWithProgress,
		]);
		this.storeInLocalStorage();
		return this;
	}

	hasUnfinishedBusiness(): boolean {
		return localStorage.getItem('newCompany') !== null;
	}

	storeInLocalStorage(): CompanyBuilder {
		localStorage.setItem('newCompany', JSON.stringify(this.storedCompanyState() || {}));
		localStorage.setItem('newCompanyInvitations', JSON.stringify(this.invitations() || {}));
		return this;
	}

	clearLocalStorage(): CompanyBuilder {
		localStorage.removeItem('newCompany');
		localStorage.removeItem('newCompanyInvitations');
		return this;
	}

	readFromLocalStorage() {
		try {
			this.storedCompanyState.set({ ...JSON.parse(localStorage.getItem('newCompany') || '{}') });
		} catch (e) {
			localStorage.removeItem('newCompany');
		}
		try {
			this.invitations.set(JSON.parse(localStorage.getItem('newCompanyInvitations') || '[]'));
		} catch (e) {
			localStorage.removeItem('newCompanyInvitations');
		}
	}
}
