import { ConcreteLocation, Field } from '@agdir/domain';
import { FieldService } from '@agdir/services';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, model, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AgdirSelectComponent } from '../agdir-select.component';
import { AgdirSelectOption } from '../model-components/agdir-select-model.component';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `agdir-location-select`,
	template: `
		<agdir-select
			(changed)="selectedValue($event)"
			[options]="options() || []"
			[size]="size()"
			[value]="value()"
			[label]="label()"
			[description]="description()"
		/>
	`,
	imports: [AgdirSelectComponent],
})
export class LocationSelectComponent {
	label = input<string>('');
	description = input<string>('');
	size = input<'normal' | 'large' | 'medium' | 'default'>('default');

	location = model<ConcreteLocation>();
	locationId = model<string>();

	value = signal<AgdirSelectOption<Field> | null>(null);

	allLocations = toSignal(inject(FieldService).getAllLocations());
	options = computed(() =>
		this.allLocations()
			// ?.filter((location) => location.locationType === LocationType.field || location.locationType === LocationType.fieldZone)
			?.map((location) => ({
				value: location,
				label: location.name,
			})),
	);

	constructor() {
		effect(
			() => {
				const location = this.location();
				if (location) {
					const locationRef = this.options()?.find((l) => l.value?.id === location.id);
					if (locationRef) {
						this.value.set(locationRef);
					}
				}
			},
			{ allowSignalWrites: true },
		);
		effect(
			() => {
				const locationId = this.locationId();
				if (locationId) {
					const locationRef = this.options()?.find((l) => l.value?.id === locationId);
					if (locationRef) {
						this.value.set(locationRef);
					}
				}
			},
			{ allowSignalWrites: true },
		);
	}

	selectedValue($event: AgdirSelectOption<ConcreteLocation>) {
		this.location?.set($event.value);
		this.locationId?.set($event.value?.id);
	}
}
