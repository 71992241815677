import { AgdirSelectComponent, AgdirSelectOption } from '@agdir/agdir-forms';
import { CompanyAccessLevel, Share } from '@agdir/domain';
import { AuthService, ProfileService, SharesService } from '@agdir/services';
import { BadgeComponent } from '@agdir/ui/badge';
import { SpinnerComponent } from '@agdir/ui/loaders';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { Component, inject, input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of, switchMap, tap } from 'rxjs';
import { AccessLevelToStringPipe } from './access-level-to-string.pipe';

@Component({
	standalone: true,
	selector: 'agdir-permission-control',
	imports: [AccessLevelToStringPipe, AsyncPipe, BadgeComponent, AgdirSelectComponent, NgIf, SpinnerComponent, JsonPipe],

	template: `
		<ng-container *ngIf="sharePermission$ | async"></ng-container>
		<ng-container *ngIf="notMe(); else justShowPermission">
			<ng-container *ngIf="iAmOwner$ | async; else justShowPermission">
				<agdir-select [selectControl]="permissionControl" class="mt-2" [options]="permissionOptions"></agdir-select>
			</ng-container>
		</ng-container>
		<agdir-spinner *ngIf="isUpdating$()"></agdir-spinner>
		<ng-template #justShowPermission>
			<agdir-badge>{{ customerShare()?.permission | accessLevelToString }}</agdir-badge>
		</ng-template>
	`,
	styles: [
		`
			:host {
				@apply flex flex-row gap-2 items-center;
			}
		`,
	],
})
export class PermissionControlComponent implements OnChanges {
	customerShare = input<Share>();
	sharesService = inject(SharesService);
	profileService = inject(ProfileService);
	stringify = inject(AccessLevelToStringPipe);
	permissionOptions: AgdirSelectOption[] = [
		{ value: CompanyAccessLevel.OWNER, label: this.stringify.transform(CompanyAccessLevel.OWNER) },
		// { value: CompanyAccessLevel.ADVISOR, label: this.stringify.transform(CompanyAccessLevel.ADVISOR) },
		{ value: CompanyAccessLevel.CRUD, label: this.stringify.transform(CompanyAccessLevel.CRUD) },
		// {value:CompanyAccessLevel.Read, label:this.accessLevelToStringPipe.transform(CompanyAccessLevel.Read)},
	];
	iAmOwner$: Observable<boolean> = inject(AuthService).amITheCurrentCompanyOwner();
	permissionControl = new FormControl<CompanyAccessLevel | null>(null, { nonNullable: true });
	notMe = signal(false);
	isUpdating$ = signal(false);

	sharePermission$ = this.permissionControl.valueChanges.pipe(
		tap(() => this.isUpdating$.set(true)),
		switchMap((newPermission) => {
			if (!newPermission || !this.customerShare()) return of();
			return this.sharesService.changePermission({
				...this.customerShare(),
				permission: newPermission,
			});
		}),
		tap(() => this.isUpdating$.set(false)),
	);

	async ngOnChanges(changes: SimpleChanges) {
		const c: Share | undefined = changes.customerShare?.currentValue;
		if (c) {
			this.permissionControl.setValue(c.permission);
			const me = this.profileService.profile();
			this.notMe.set(c.customerId !== me?.id);
		}
	}
}
