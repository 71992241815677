import { CompanyService } from '@agdir/services';
import { Equipment } from '@agdir/vogn/domain';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, model, output, viewChild } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { NzFormItemComponent, NzFormLabelComponent } from 'ng-zorro-antd/form';
import { NzColDirective } from 'ng-zorro-antd/grid';
import { map, Observable } from 'rxjs';
import { AddEquipmentComponent } from './add-equipment.component';
import { EquipmentSelectedListComponent } from './equipment-selected-list.component';

@Component({
	selector: 'agdir-equipment-select',
	standalone: true,
	template: `
		<nz-form-item>
			<nz-form-control>
				@if (label() && size() == 'normal') {
					<nz-form-label>{{ translate() ? (label() | transloco) : label() }}</nz-form-label>
				}
				@if (label() && size() == 'large') {
					<div class="text-3xl md:text-4xl font-bold flex items-center justify-start gap-5">
						{{ translate() ? (label() | transloco) : label() }}
					</div>
				}
				<agdir-add-equipment
					#addEquipmentButton
					[title]="label()"
					[label]="modalTriggerLabel()"
					[description]="description()"
					[(currentSelection)]="equipment"
					class="hidden"
				/>
				@if (description() && size() == 'large') {
					<div class="text-xl flex-1 font-light mb-5 flex items-center justify-start gap-5">
						{{ translate() ? (description() | transloco) : description() }}
					</div>
				}
				<div class="mb-5" [innerHtml]="linkToManagement() | transloco: { companyId: currentCompanyId$ | async }"></div>
				<agdir-equipment-selected-list [(list)]="equipment" />
			</nz-form-control>
		</nz-form-item>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NzFormLabelComponent,
		TranslocoPipe,
		AddEquipmentComponent,
		EquipmentSelectedListComponent,
		AsyncPipe,
		NzColDirective,
		NzFormItemComponent,
		EquipmentSelectedListComponent,
		JsonPipe,
	],
})
export class EquipmentSelectComponent {
	equipment = model<Equipment[]>([]);
	label = input<string>();
	linkToManagement = input<string>();
	placeholder = input<string>();
	horizontal = input<'false' | 'true' | boolean>();
	size = input<string>();
	description = input<string>();
	modalTriggerLabel = input<string>();
	translate = input<'false' | 'true' | boolean>(true);
	currentCompanyId$: Observable<unknown> = inject(CompanyService)
		.getCurrentCompany()
		.pipe(map((s) => s?.id));
	equipmentUpdated = output<Equipment[]>();
	addEquipmentButton = viewChild(AddEquipmentComponent);
}
