import { Equipment } from '../equipment';
import { VendorName } from '../vendor-name';
import { JohnDeereMachine } from './john-deere-machine';

export class JohnDeereMapper {
	static toEquipment(m: JohnDeereMachine): Equipment {
		return {
			companyId: '',
			id: +m.id,
			name: m.name,
			vendor: VendorName.JOHN_DEERE,
			isOnline: m.telematicsState !== 'inoperative',
			// manufacturer: m.equipmentMake.name?.toLocaleLowerCase().replace(/ /g, '-'),
			// type: m.equipmentType.name,
			model: m.equipmentModel.name,
		};
	}
}
