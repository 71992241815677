import { Share } from '@agdir/domain';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'agdirSharePeopleName',
	pure: false,
	standalone: true,
})
export class AgdirSharePeopleNamePipe implements PipeTransform {
	transform(items: Share[], ...args: any[]): any {
		return (items || []).map((item) => item.customer?.name + ' ' + item.customer?.surname).join(', ');
	}
}
