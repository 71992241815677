import { ProfileService } from '@agdir/services';
import { inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'agdirPeopleName',
	pure: false,
	standalone: true,
})
export class AgdirPeopleNamePipe implements PipeTransform {
	transform(items: Partial<{ name: string; surname: string }>[], ...args: any[]): any {
		return (items || []).map((item) => `${item.name} ${item.surname}`).join(', ');
	}
}

@Pipe({
	name: 'agdirPersonName',
	pure: false,
	standalone: true,
})
export class AgdirPersonNamePipe implements PipeTransform {
	transform(item?: Partial<{ name: string; surname: string }>, ...args: any[]): any {
		return item && `${item.name} ${item.surname}`;
	}
}

@Pipe({
	name: 'agdirCustomerId',
	pure: false,
	standalone: true,
})
export class AgdirCustomerId implements PipeTransform {
	profileService = inject(ProfileService);

	transform(items?: Partial<{ id: string }> | undefined | null): any {
		if (!items) return this.profileService.profile()?.id;
		return items.id;
	}
}
